import { filter, find } from 'lodash'
import {
  APISalonSubscriptionLara,
  APIUserSalonContext,
  LoggedInUser,
  LoggedInUserSalonContext,
  LoggedInUserSalonPermissions, LoggedInUserSquareContext, SalonContextRole,
  SalonMember,
  SalonMemberLara,
  SalonRole,
  UserMeta,
  UserRole,
} from './interfaces'
import { EMPTY_TIER } from '../labor/utils'
import { USER_PERMISSIONS } from './constants'

export const getActiveSalonContext = (
  salonContexts: APIUserSalonContext[],
  salonId?: any,
): APIUserSalonContext | null => {
  if (salonId) {
    const context = find(salonContexts, ['salon_id', parseInt(salonId)])
    return context || null
  }
  if (salonContexts.length === 1) {
    return salonContexts[0]
  }
  if (salonContexts.length > 1) {
    const firstActiveSalon = find(salonContexts, ( context ) => {
      return (
        context.customer.stripe_active_account &&
        ['trialing', 'trialling', 'active'].includes(context.customer.stripe_subscription_status)
      )
    })
    if (firstActiveSalon) {
      return firstActiveSalon
    } else {
      return salonContexts[0]
    }
  }
  return null
}

export const getLoggedInUserActiveSalonContext = (
  salonContexts: LoggedInUserSalonContext[],
  salonId?: any,
): LoggedInUserSalonContext | null => {
  if (salonId) {
    const context = find(salonContexts, ['salonId', parseInt(salonId)])
    return context || null
  }
  if (salonContexts.length === 1) {
    return salonContexts[0]
  }
  if (salonContexts.length > 1) {
    const firstActiveSalon = find(salonContexts, ( context ) => {
      return !!( context && context.subscription && context.subscription.isActive )
    })
    if (firstActiveSalon) {
      return firstActiveSalon
    } else {
      return salonContexts[0]
    }
  }
  return null
}

export const getLoggedInUserActiveSquareContext = (
  squareContexts: LoggedInUserSquareContext[],
  salonId?: any,
): LoggedInUserSquareContext | null => {
  if (salonId) {
    const context = find(squareContexts, ['salonId', parseInt(salonId)])
    return context || null
  }
  if (squareContexts.length === 1) {
    return squareContexts[0]
  }
  if (squareContexts.length > 1) {
    const firstActiveContext = find(squareContexts, ( context ) => {
      return !!( context && context.merchantId)
    })
    if (firstActiveContext) {
      return firstActiveContext
    } else {
      return squareContexts[0]
    }
  }
  return null
}

export const EMPTY_STYLIST: SalonMember = {
  userId: -1,
  stylistId: -1,

  firstName: '',
  lastName: '',
  email: '',

  photoUrl: '',
  roles: [],
  laborTier: EMPTY_TIER(),
}

export const EMPTY_SALON_ROLE: SalonRole = {
  userId: -1,
  role: {
    roleId: -1,
    roleName: 'stylist',
    permissions: [],
  },
}

export const filterSalonMembers = ( members: SalonMember[], searchText: string ): SalonMember[] => {
  const normalizedText = searchText.toLowerCase()
  return filter(members, ( member ) => {
    if (member.firstName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (member.lastName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (member.email.toLowerCase().includes(normalizedText)) {
      return true
    }
    return false
  })
}
// TODO - replace the name to filterSalonMembers once the users api completely replaced
export const filterSalonMembersLara = ( members: SalonMemberLara[], searchText: string ): SalonMemberLara[] => {
  const normalizedText = searchText.toLowerCase()
  return filter(members, ( member ) => {
    if (member.firstName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (member.lastName.toLowerCase().includes(normalizedText)) {
      return true
    }
    return false
  })
}

export const getHighestRole = ( roles: string[] ): UserRole => {
  if (roles.includes('superuser')) {
    return 'superuser'
  }
  if (roles.includes('owner')) {
    return 'owner'
  }
  return 'stylist'
}

export const roleMeetsRoleRequirements = ( highestRole: UserRole, requiredRole: UserRole ) => {
  if (highestRole === 'superuser') {
    return true
  }

  if (highestRole === 'owner' && ['stylist', 'owner'].includes(requiredRole)) {
    return true
  }

  if (highestRole === 'stylist' && ['stylist'].includes(requiredRole)) {
    return true
  }
  return false
}

export const redirectToAngularAdmin = ( token: string, userId: string | number ) => {
  const ngBase = process.env.REACT_APP_ANGULAR_HOST
  window.location.href = `${ngBase}/utils/redirect?token=${token}&user_id=${userId}&admin=true`
}
export const hasInactiveSubscription = ( salonContext: LoggedInUserSalonContext ): boolean => {
  const noSubscription = !salonContext?.subscription
  const isInactiveSubscription = !salonContext?.subscription?.isActive
  const cancelledOrPausedSubscription = salonContext?.subscription ? ['cancelled', 'paused'].includes(salonContext?.subscription?.subscriptionStatus?.toLowerCase()) : true
  return (
    noSubscription || isInactiveSubscription || cancelledOrPausedSubscription
  )
}

export const hasNoSubscription = ( salonContext: LoggedInUserSalonContext ): boolean => {
  return !salonContext?.subscription?.isActive && salonContext?.subscription?.subscriptionStatus === null
}

// todo: make sure the right salon context is passed in here
export const isStylistUser = ( userSalonPermissions: LoggedInUserSalonPermissions ): boolean => {
  return ['stylist', 'frontdesk'].includes(userSalonPermissions.role)
}

export const isSuperUser = ( user: LoggedInUser ) => {
  return user?.isSuperuser || user?.token?.startsWith('5--')
}

// there are more concise ways of doing this but i wanted to business logic layed out clearly
export const userHasInventoryPermissions = ( userPermissions: LoggedInUserSalonPermissions | null ): boolean => {
  if (userPermissions?.permissions.includes(USER_PERMISSIONS.inventoryAddStock)) {
    return true
  }
  if (userPermissions?.permissions.includes(USER_PERMISSIONS.inventorySnapshot)) {
    return true
  }
  if (userPermissions?.permissions.includes(USER_PERMISSIONS.inventoryStockAlerts)) {
    return true
  }
  if (userPermissions?.permissions.includes(USER_PERMISSIONS.inventoryAudit)) {
    return true
  }
  return false
}

export const getHighestSalonStatusFromSubscriptionsLara = ( subscriptions: APISalonSubscriptionLara[] ): APISalonSubscriptionLara | null => {
  // todo: make sure this is what we actually want if they have no subscriptions
  if (subscriptions.length === 0) {
    return null;
  }
  if (subscriptions.length === 1) {
    return subscriptions[0];
  }

  const statusOrder = ['active', 'in_trial', 'non_renewing', 'paused', 'cancelled'];

  for (const preferredStatus of statusOrder) {
    const foundSub = subscriptions.find(sub => sub.status === preferredStatus);
    if (foundSub) {
      return foundSub;
    }
  }

  return subscriptions[0]; // Or some other default status
};

export const getSalonTypeFromSubscriptionLara = ( subscription: APISalonSubscriptionLara | null ): 'salon' | 'independent' => {
  if (!subscription) {
    return 'independent'
  }
  if (subscription.plan_id.includes('team') || subscription.plan_id.includes('salon') || subscription.plan_id.includes('temporary-access')) {
    return 'salon'
  } else {
    return 'independent'
  }
}

export const filterUsersMeta = ( users: UserMeta[], searchText: string ): UserMeta[] => {
  const normalizedText = searchText.toLowerCase()
  return filter(users, ( user ) => {
    if (user.firstName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (user.lastName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (user.email.toLowerCase().includes(normalizedText)) {
      return true
    }
    return false
  })
}

export const getRoleForSalonFromSalonContexts = (salonId: number, salonContexts: LoggedInUserSalonContext[]): SalonContextRole | null => {
   const salonContext = salonContexts.find(context => context.salonId === salonId)
  return salonContext ? salonContext.role : null
}

export const getIsUserOwnerOfAnySalon = (user: LoggedInUser): boolean => {
  return user.salonContexts.some(salonContext => salonContext.role?.role.roleId === 1)
}
